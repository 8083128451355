import { ButtonProps } from "@mui/material";
import Button from "@mui/material/Button";
import { ElementType } from "react";
import { ButtonTypeMap } from "@mui/material/Button/Button";

type PrimaryButtonProps<
  RootComponent extends ElementType = ButtonTypeMap["defaultComponent"],
  AdditionalProps = {},
> = Omit<ButtonProps<RootComponent, AdditionalProps>, "variant" | "color">;

export default function PrimaryButton<T extends ElementType, U>(
  props: PrimaryButtonProps<T, U>,
) {
  return <Button {...props} color={"primary"} variant={"contained"} />;
}
