import { createBrowserRouter, Navigate, RouterProvider } from "react-router";
import { Loaders } from "@/services/loaders.ts";
import { lazy, Suspense } from "react";
import RequireCompany from "@/components/company-required.component.tsx";
import { Permission } from "@/libs/permissions/permissions.enum.ts";
import RequirePermission from "@/components/require-permission.tsx";
import BackdropLoader from "@/components/backdrop-loader.tsx";

const ReportsPage = lazy(() => import("@/pages/reports/reports.page.tsx"));

const DocumentSendingFlowPage = lazy(
  () => import("@/pages/komidoc-sending-flow/komidoc-sending-flow.page.tsx"),
);

const EmployeeFlowsPage = lazy(
  () => import("@/pages/employee-flows/employee-flows.page.tsx"),
);

const EmployeesKmsPage = lazy(
  () => import("@/pages/employees-kms/employees-kms.page.tsx"),
);

const ScheduleEmployeeKMSPage = lazy(
  () => import("@/pages/schedule-employee-kms/schedule-employee-kms.page.tsx"),
);

const DeleteEmployeeKmPage = lazy(
  () => import("@/pages/delete-employee-km/delete-employee-km.page.tsx"),
);

const UsersPage = lazy(() => import("@/pages/users-list/users.page.tsx"));

const UsersEditPage = lazy(
  () => import("@/pages/users-edit/users-edit.page.tsx"),
);

const HolidaysEditPage = lazy(
  () => import("@/pages/holidays-edit/holidays-edit.page.tsx"),
);
const HolidaysDeletePage = lazy(
  () => import("@/pages/holidays-delete/holidays-delete.page.tsx"),
);
const FlowDetailsPage = lazy(
  () => import("@/pages/flow-details/flow-details.page.tsx"),
);
const MovementGenerationPage = lazy(
  () => import("@/pages/movement-generation/movement-generation.page.tsx"),
);
const IntegrationSettingsPage = lazy(
  () => import("@/pages/integration-settings/integration-settings.page.tsx"),
);
const CustomErrorPage = lazy(
  () => import("@/components/custom-error.page.tsx"),
);
const FlowsPage = lazy(() => import("@/pages/flows-list/flows.page.tsx"));
const CompaniesCreatePage = lazy(
  () => import("@/pages/companies-create/companies.create.tsx"),
);
const HolidayCreatePage = lazy(
  () => import("@/pages/holidays-create/holidays-create.page.tsx"),
);
const HolidaysPage = lazy(
  () => import("@/pages/holidays-list/holidays.page.tsx"),
);
const GeneralSettingsPage = lazy(
  () => import("@/pages/general-settings/general-settings.page.tsx"),
);
const EmployeeHistoryPage = lazy(
  () => import("@/pages/employee-history/employee-history.page.tsx"),
);
const EmployeeMovementPage = lazy(
  () => import("@/pages/employee-movement/employee-movement.page.tsx"),
);
const CompaniesEditPage = lazy(
  () => import("@/pages/companies-edit/companies-edit.page.tsx"),
);
const ClientEditPage = lazy(
  () => import("@/pages/clients-edit/client-edit.page.tsx"),
);
const CompaniesPage = lazy(
  () => import("@/pages/companies-list/companies.page.tsx"),
);
const ClientCreate = lazy(
  () => import("@/pages/client-create/client-create.page.tsx"),
);
const ClientsPage = lazy(() => import("@/pages/clients-list/clients.page.tsx"));
const EmployeeCreatePage = lazy(
  () => import("@/pages/employees-create/employees-create.page.tsx"),
);
const EmployeesEditPage = lazy(
  () => import("@/pages/employees-edit/employees-edit.page.tsx"),
);
const DashboardPage = lazy(
  () => import("@/pages/dashboard/dashboard.page.tsx"),
);
const EmployeesPage = lazy(
  () => import("@/pages/employees-list/employees.page.tsx"),
);
const MainLayout = lazy(() => import("@/layouts/main/main.layout.tsx"));

const router = createBrowserRouter([
  {
    Component: MainLayout,
    loader: Loaders.loadRequiredData,
    id: "main",
    errorElement: <CustomErrorPage />,
    HydrateFallback: BackdropLoader,
    children: [
      { index: true, Component: DashboardPage },
      {
        path: "employees",
        children: [
          {
            index: true,
            element: (
              <RequirePermission permissions={Permission.ReadEmployees}>
                <RequireCompany>
                  <EmployeesPage />
                </RequireCompany>
              </RequirePermission>
            ),
          },
          {
            path: "create",
            element: (
              <RequirePermission permissions={Permission.WriteEmployees}>
                <EmployeeCreatePage />
              </RequirePermission>
            ),
          },
          {
            path: ":id",
            id: "employee",
            loader: Loaders.getEmployee,
            element: (
              <RequirePermission permissions={Permission.WriteEmployees}>
                <EmployeesEditPage />
              </RequirePermission>
            ),
            children: [
              { path: "movements", element: <EmployeeMovementPage /> },
              { path: "history", element: <EmployeeHistoryPage /> },
              { path: "flows", element: <EmployeeFlowsPage /> },
              {
                path: "kms",
                element: <EmployeesKmsPage />,
                children: [
                  { index: true, element: null },
                  { path: "create", element: <ScheduleEmployeeKMSPage /> },
                  {
                    path: ":kmId",
                    id: "km",
                    loader: Loaders.getEmployeeKm,
                    element: <DeleteEmployeeKmPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "globals",
        children: [
          {
            path: "clients",
            element: (
              <RequirePermission permissions={Permission.UpdateClients}>
                <ClientsPage />
              </RequirePermission>
            ),
            children: [
              { path: "create", element: <ClientCreate /> },
              {
                path: ":id",
                loader: Loaders.getClient,
                id: "client",
                element: <ClientEditPage />,
              },
            ],
          },
          {
            path: "holidays",
            element: <HolidaysPage />,
            children: [
              { path: "create", element: <HolidayCreatePage /> },
              {
                path: ":id",
                loader: Loaders.getHoliday,
                id: "holiday",
                children: [
                  { index: true, element: <HolidaysEditPage /> },
                  { path: "delete", element: <HolidaysDeletePage /> },
                ],
              },
            ],
          },
          {
            path: "companies",
            children: [
              { index: true, element: <CompaniesPage /> },
              { path: "create", element: <CompaniesCreatePage /> },
              {
                path: ":id",
                loader: Loaders.getCompany,
                id: "company",
                element: <CompaniesEditPage />,
              },
            ],
          },
          {
            path: "users",
            element: <UsersPage />,
            children: [
              { index: true, element: null },
              {
                path: ":id",
                loader: Loaders.getUser,
                id: "user",
                element: <UsersEditPage />,
              },
            ],
          },
        ],
      },
      {
        path: "flows",
        children: [
          {
            index: true,
            element: (
              <RequirePermission
                permissions={[
                  Permission.ReadFlowHistory,
                  Permission.RunMovementGenerationProcess,
                  Permission.RunDocumentSendingProcess,
                ]}
              >
                <RequireCompany>
                  <FlowsPage />
                </RequireCompany>
              </RequirePermission>
            ),
          },
          {
            path: ":id",
            element: (
              <RequireCompany>
                <FlowDetailsPage />
              </RequireCompany>
            ),
            loader: Loaders.getFlow,
            id: "flow",
          },
          {
            path: "movement-generation",
            element: (
              <RequireCompany>
                <MovementGenerationPage />
              </RequireCompany>
            ),
          },
          {
            path: "document-sending",
            element: (
              <RequireCompany>
                <DocumentSendingFlowPage />
              </RequireCompany>
            ),
          },
        ],
      },
      {
        path: "settings",
        children: [
          { index: true, element: <Navigate to={"/settings/overview"} /> },
          {
            path: "general",
            loader: Loaders.getGeneralSettings,
            id: "general-settings",
            element: <GeneralSettingsPage />,
          },

          {
            path: "integrations",
            loader: Loaders.getIntegrationSettings,
            id: "integration-settings",
            element: <IntegrationSettingsPage />,
          },
        ],
      },
      {
        path: "reports",
        Component: ReportsPage,
      },
    ],
  },
]);

export default function AppRouter() {
  return (
    <>
      <Suspense fallback={<BackdropLoader />}>
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
}
