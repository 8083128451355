import { useLoadedPermissions } from "@/services/companies.ts";
import { checkPermission } from "@/libs/permissions/check-permission.ts";
import { Permission } from "@/libs/permissions/permissions.enum.ts";

export const useHasPermissions = (permissions: Permission[]) => {
  const user_permissions = useLoadedPermissions();
  return permissions.some((permission) =>
    checkPermission(user_permissions, permission),
  );
};
