import { z, ZodSchema } from "zod";
import { GEN_CURRENT_COMPANY } from "@/services/companies.ts";
import axios from "axios";
import msalInstance from "@/msal-instance.ts";
import logger from "@/libs/logger.ts";

export const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

async function getAccessToken() {
  await msalInstance.initialize();

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    // Prompt user to log in
    try {
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
      return await getAccessToken();
    } catch (error) {
      logger.debug("Login failed:", error);
      throw error;
    }
  } else if (msalInstance.getActiveAccount() === null) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  const tokens = await msalInstance.acquireTokenSilent({
    scopes: ["api://gensim.primeit.pt/.default"],
  });
  return tokens.accessToken;
}

msalInstance.addEventCallback(
  async (message) => {
    switch (message.eventType) {
      case "msal:initializeEnd":
        {
          logger.debug("Msal initialized");
        }
        break;
      default:
        logger.debug("msal", message);
    }
  },
  ["msal:initializeEnd"],
);

apiClient.interceptors.request.use(async (config) => {
  if (!config.headers.Authorization) {
    const token = await getAccessToken();
    config.headers.set("Authorization", `Bearer ${token}`);
  }

  const currentCompany = JSON.parse(
    localStorage.getItem(GEN_CURRENT_COMPANY) ?? "null",
  );
  if (currentCompany) {
    config.headers.set("X-Tenant", currentCompany.id);
  }
  return config;
});

export const zodList = (schema: ZodSchema) => {
  return z.array(schema);
};
