import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { memo, useCallback } from "react";

interface BackdropLoaderProps {
  open?: boolean;
  handleClose?: () => void;
}

function BackdropLoader({ open = true, handleClose }: BackdropLoaderProps) {
  const onClick = useCallback(() => {
    if (typeof handleClose === "function") {
      handleClose();
    }
  }, [handleClose]);

  return (
    <Backdrop
      sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
      open={open}
      onClick={onClick}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default memo(BackdropLoader);
