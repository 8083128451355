import { Configuration, PublicClientApplication } from "@azure/msal-browser";

const configuration: Configuration = {
  auth: {
    clientId: "27e38e49-1e9f-4f86-9d25-d9d21fe90464",
    authority:
      "https://login.microsoftonline.com/1e90c2a4-4c06-473e-a488-f46314220305",
    redirectUri: `${location.protocol}//${location.host}`,
  },
};

const pca = new PublicClientApplication(configuration);

export default pca;
