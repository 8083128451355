import { Permission } from "@/libs/permissions/permissions.enum.ts";
import logger from "@/libs/logger.ts";

export const checkPermission = (
  user_permissions: Permission[],
  permission: Permission,
) => {
  if (!user_permissions || user_permissions.length === 0) {
    return false;
  }
  const hasPermission = user_permissions.includes(permission);
  if (!hasPermission) {
    logger.debug("No permission for", permission, "in", user_permissions);
  }
  return hasPermission;
};
