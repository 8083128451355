import { Suspense } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import CssBaseline from "@mui/material/CssBaseline";
import { LicenseInfo } from "@mui/x-license";
import { QueryClientProvider } from "@tanstack/react-query";
import { pt } from "date-fns/locale/pt";

import { queryClient } from "@/query-client";
import AppRouter from "@/routes";
import { RecoilRoot } from "recoil";
import { SnackbarProvider } from "notistack";
import BackdropLoader from "@/components/backdrop-loader.tsx";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import msalInstance from "@/msal-instance.ts";
import { ErrorBoundary } from "@sentry/react";

LicenseInfo.setLicenseKey(
  "05cee51a740b2f78636f4d0e8084fedaTz05NzUxNyxFPTE3NTcwNzI0ODMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==",
);

const authRequest = {
  scopes: ["openid", "profile"],
};

function App() {
  return (
    <RecoilRoot>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
        <CssBaseline />
        <ErrorBoundary>
          <SnackbarProvider maxSnack={10}>
            <Suspense fallback={<BackdropLoader />}>
              <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate
                  interactionType={InteractionType.Redirect}
                  authenticationRequest={authRequest}
                >
                  <QueryClientProvider client={queryClient}>
                    <AppRouter />
                  </QueryClientProvider>
                </MsalAuthenticationTemplate>
              </MsalProvider>
            </Suspense>
          </SnackbarProvider>
        </ErrorBoundary>
      </LocalizationProvider>
    </RecoilRoot>
  );
}

export default App;
