import { PropsWithChildren, useMemo } from "react";
import { useHasPermissions } from "@/libs/permissions/use-has-permissions.ts";
import { Permission } from "@/libs/permissions/permissions.enum.ts";
import { Box, Container } from "@mui/material";
import UndrawSecurity from "@/assets/icons/UndrawSecurity.tsx";
import Typography from "@mui/material/Typography";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";
import PrimaryButton from "@/components/primary-button.tsx";

interface RequirePermissionProps {
  permissions: Permission | Permission[];
}

export default function RequirePermission({
  permissions,
  children,
}: PropsWithChildren<RequirePermissionProps>) {
  const { t } = useTranslation("error");
  const mappedPermissions = useMemo(() => {
    if (Array.isArray(permissions)) return permissions;
    return [permissions];
  }, [permissions]);

  const isAllowed = useHasPermissions(mappedPermissions);

  if (isAllowed) return <>{children}</>;
  return (
    <>
      <Container sx={{ textAlign: "center", marginTop: 8 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <UndrawSecurity color={"primary"} sx={{ width: 500, height: 500 }} />
          <Typography variant="h5" component="div" gutterBottom>
            Not authorized.
          </Typography>
          <Typography variant={"body1"} component={"div"}>
            You are not authorized to access this page.
          </Typography>
          <PrimaryButton component={Link} to={"/"}>
            {t("go_to_home")}
          </PrimaryButton>
        </Box>
      </Container>
    </>
  );
}
