const log = (
  type: "log" | "error",
  label: string,
  color: string,
  ...content: unknown[]
) => {
  if (sessionStorage.getItem("_debug")) {
    console[type](
      label,
      color
        ? `${color};border-radius: 5px;padding-left: 12px;padding-right: 12px;padding-top: 6px; padding-bottom: 6px`
        : "",
      ...content,
    );
  }
};

const logger = (type: "log" | "error", ...content: unknown[]) => {
  log(type, " %cLOG", "color: blue;", ...content);
};

logger.debug = (...args: unknown[]) => {
  log("log", " %cDEBUG", "color: black;background: gray;", ...args);
};

logger.error = (...args: unknown[]) => {
  log("error", " %cERROR", "color: white;background:red;", ...args);
};

export default logger;
