import { apiClient } from "@/services/http.ts";
import { useMutation, useQuery } from "@tanstack/react-query";
import { z } from "zod";
import qs from "qs";
import { AddressSchema, pageSchema, ParamsType } from "@/services/common.ts";
import { useCurrentCompany } from "@/services/companies.ts";
import { tenantsZodSchema } from "@/services/tenants.ts";

const ClientSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  active: z.boolean(),
  tenants: tenantsZodSchema,
});

export type ClientType = z.infer<typeof ClientSchema>;

const getClients = async (
  { paginationModel, sortModel, filterModel }: ParamsType,
  signal: AbortSignal,
) => {
  const response = await apiClient.post("/clients/search", filterModel, {
    params: {
      page: paginationModel.page,
      size: paginationModel.pageSize,
      sort: sortModel?.map(
        (sort) => `${sort.field.replace(".id", ".name")},${sort.sort}`,
      ),
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
    signal,
  });
  return pageSchema(ClientSchema).parse(response.data);
};

export const useClients = (params: ParamsType) => {
  const [company] = useCurrentCompany();
  return useQuery({
    queryKey: ["clients", params],
    queryFn: ({ signal }) => getClients(params, signal),
    enabled: Boolean(company),
  });
};

export const CreateClientRequestSchema = z.object({
  name: z.string().min(1),
  offices: z.array(
    z.object({
      address: AddressSchema,
    }),
  ),
});

export type CreateClientRequestType = z.infer<typeof CreateClientRequestSchema>;

const CreateClientResponseSchema = z.string().uuid();

type CreateClientResponseType = z.infer<typeof CreateClientResponseSchema>;

const createClient = async (data: CreateClientRequestType) => {
  const response = await apiClient.post<CreateClientResponseType>(
    "/clients",
    data,
  );
  return CreateClientResponseSchema.parse(response.data);
};

export const useCreateClient = () =>
  useMutation({
    mutationKey: ["clients"],
    mutationFn: createClient,
  });

export const UpdateClientRequestSchema = z.object({
  id: z.string().uuid().optional(),
  name: z.string().min(1),
  active: z.boolean().optional(),
  offices: z
    .array(
      z.object({
        id: z.string().uuid().nullable().optional(),
        address: AddressSchema,
        active: z.boolean().optional(),
      }),
    )
    .nonempty(),
  tenants: z.preprocess(
    (val) =>
      val && Array.isArray(val) ? val.map(({ id, name }) => ({ id, name })) : undefined,
    z.array(z.object({ id: z.string().uuid(), name: z.string() })).nonempty(),
  ),
});

export type UpdateClientRequestType = z.infer<typeof UpdateClientRequestSchema>;

const updateClient = async (
  clientId: string,
  data: UpdateClientRequestType,
) => {
  const response = await apiClient.put(`/clients/${clientId}`, data);
  return response.data;
};

export const useUpdateClient = (clientId: string) =>
  useMutation({
    mutationKey: ["client", clientId],
    mutationFn: (variables: UpdateClientRequestType) =>
      updateClient(clientId, variables),
  });

const GetClientResponseSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  active: z.boolean(),
  offices: z
    .array(
      z.object({
        id: z.string().uuid(),
        address: AddressSchema,
        active: z.boolean(),
      }),
    )
    .nonempty(),
  tenants: z.array(
    z.object({
      id: z.string().uuid(),
      name: z.string(),
    }),
  ).nonempty(),
});

export type GetClientResponseType = z.infer<typeof GetClientResponseSchema>;

export const getClient = async (clientId: string, signal: AbortSignal) => {
  const response = await apiClient.get(`/clients/${clientId}`, {
    signal,
  });
  return GetClientResponseSchema.parse(response.data);
};
