import { useCurrentCompany } from "@/services/companies.ts";
import { PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import image from "../assets/raws/undraw_selection_re_ycpo.svg";
import Stack from "@mui/material/Stack";

export default function RequireCompany({ children }: PropsWithChildren) {
  const [company] = useCurrentCompany();
  const { t } = useTranslation("company_required");
  if (!company) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
          height: "100%",
        }}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <img src={image} alt={"Woman selecting an option"} />
          <Typography variant="h4" component="div">
            {t("message")}
          </Typography>
        </Stack>
      </Box>
    );
  }
  return children;
}
