export enum Permission {
  UpdateGeneralSettings = "Settings.Write.General",
  UpdateCompanies = "Settings.Write.Companies",
  UpdateClients = "Settings.Write.Clients",
  UpdateUsers = "Settings.Write.Users",
  UpdateHolidays = "Settings.Write.Holidays",
  UpdateIntegrationSettings = "Settings.Write.Integration",
  RunDocumentSendingProcess = "Flows.Run.DocumentSending",
  RunMovementGenerationProcess = "Flows.Run.MovementGeneration",
  ReadFlowHistory = "Flows.Read.Executions",
  ReadEmployees = "Employees.Read",
  WriteEmployees = "Employee.Write",
  DevAccess = "Dev.Access",
}
